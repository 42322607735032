<template>
  <section v-if="getContactData" class="footer-bottom">
    <div class="row">
      <div class="col-logo">
        <ResimoLogotype />
      </div>

      <div v-if="getContactData && getContactData.offices" class="col-contact">
        <address
          v-for="(office, index) in filteredOffices"
          :key="`${office.email}__${index}`"
          class="col-address"
          itemscope
          itemtype="https://schema.org/Place"
        >
          <div class="col-address-inner">
            <p class="location">{{ office.location[getCurrentLanguage] }}</p>
            <a
              class="phone"
              title="phone"
              :href="`tel:${office.phone}`"
              itemprop="telephone"
              @click="sendGtag"
              >{{ office.phone }}</a
            >
            <a class="email" title="email" :href="`mailto:${office.email}`" itemprop="email">{{
              office.email
            }}</a>
            <p
              class="contact-address"
              itemprop="address"
              itemscope
              itemtype="https://schema.org/PostalAddress"
            >
              {{ office.address[getCurrentLanguage] }}
            </p>
          </div>
        </address>
      </div>
      <div class="col-social --full-width">
        <div class="copyright-container">
          <p class="copyright-text">{{ COPYRIGHT[getCurrentLanguage] }}</p>
          <nuxt-link class="privacy-policy-link" :to="ROUTES.PRIVACY_POLICY.path"
            >Policy Privacy</nuxt-link
          >
        </div>
        <div class="social-links">
          <a title="Youtube link" :href="getContactData.ytUrl" class="social-link --yt">
            <YoutubeIcon />
          </a>
          <a
            title="Linkedin link"
            :href="getContactData.linkedinUrl"
            class="social-link --linkedin"
          >
            <LinkedinIcon />
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { ROUTES } from '../../utils/routes'
import ResimoLogotype from '@/components/icons/ResimoLogotype'
import LinkedinIcon from '@/components/icons/LinkedinIcon'
import YoutubeIcon from '@/components/icons/YoutubeIcon'

const COPYRIGHT = {
  en: 'All rights reserved by Resimo.',
  fr: 'Tous droits réservés par Resimo.',
}

export default {
  name: 'AppFooterBottom',
  components: { ResimoLogotype, LinkedinIcon, YoutubeIcon },
  data() {
    return {
      COPYRIGHT,
      ROUTES,
    }
  },
  computed: {
    ...mapGetters(['getContactData', 'getCurrentLanguage']),
    filteredOffices() {
      if (!this.getContactData?.offices?.office) return []
      return this.getContactData.offices.office.filter(
        (office) => office.email !== 'warsaw@resimo.com'
      )
    },
  },
  methods: {
    getFirstKeyOfObject(obj) {
      return obj[Object.keys(obj)[0]]
    },
    sendGtag() {
      if (process.client) {
        window.gtag('event', 'conversion', { send_to: 'AW-380967499/7tS7COy4uN4CEMu01LUB' })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/layout/AppFooterBottom';
</style>
